import {HttpClient} from "jrmc/vue/shared/api/http-client";
import {SendMode} from "jrmc/vue/shared/models/paperletter/SendMode";
import {SSRContext} from "@vue/server-renderer";

export class SendModeApi extends HttpClient {
  public constructor(ssrContext?: SSRContext) {
    super('/api/v1/paperletter/send_modes', ssrContext);
  }

  async getSendModes() {
    return this.instance.get<Array<SendMode>>(`/`,);
  }
}
