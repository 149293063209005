import {defineStore} from "pinia";

interface CsrfState {
  csrfToken: string | null
}

export const useCsrfStore = defineStore('csrf', {
  state: (): CsrfState => ({
    csrfToken: null,
  }),
  actions: {
    setCsrfToken(payload: string) {
      this.csrfToken = payload;
    }
  }
})
