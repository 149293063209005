import axios, {AxiosInstance, AxiosResponse} from 'axios';
import {SSRContext} from "@vue/server-renderer";

declare module 'axios' {
  interface AxiosResponse<T = any> extends Promise<T> {
  }
}

export abstract class NoCsrfHttpClient {
  protected readonly instance: AxiosInstance;

  public constructor(baseURL: string, ssrContext?: SSRContext) {
    let finalUrl = baseURL
    if (ssrContext) {
      const ssrApiUrl = ssrContext['VITE_SSR_API_URL']
      finalUrl = ssrApiUrl + baseURL
    }
    this.instance = axios.create({
      timeout: 60000,
      baseURL: finalUrl,
    });

    this._initializeResponseInterceptor();
  }

  protected _initializeResponseInterceptor () {
    this.instance.interceptors.request.use(
      async (config) => {

        return config
      },

      (error) => {
        return Promise.reject(error)
      }
    )
    this.instance.interceptors.response.use(
      this._handleResponse,
      this._handleError,
    );
  };

  protected _handleResponse = ({data}: AxiosResponse) => data;

  protected _handleError = (error: any) => Promise.reject(error);
}
