declare global {
  interface Window { dataLayer: any[]; }
}

export default new class Analytics {
  gtag(type: string, name: string, data?: any){
    if (window.dataLayer) {
      window.dataLayer.push(arguments);
    }
  }

  event(name: string, data?: any) {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: name,
        ...data
      });
    }
  }
}
