import { LetterCharacteristic } from "jrmc/vue/shared/models/paperletter/LetterCharacteristic";
import {HttpClient} from "jrmc/vue/shared/api/http-client";
import {LetterPrice} from "jrmc/vue/shared/models/paperletter/LetterPrice";
import {SSRContext} from "@vue/server-renderer";

export class LetterPriceApi extends HttpClient {
    public constructor(ssrContext?: SSRContext) {
      super('/api/v1/paperletter/price', ssrContext);
    }
    async getLetterPrice(letterElement : LetterCharacteristic){
        const parameters = {
            sendMode: letterElement.id,
            pageCount : letterElement.quantity,
            staplingCount : letterElement.isStappled && letterElement.quantity >= 2 ? letterElement.stapplingCount : null
        }
        return this.instance.get<LetterPrice>('',{params: parameters})
    }
}
