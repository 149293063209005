import CsrfService from 'jrmc/vue/shared/services/csrf'
import {SSRContext} from "@vue/server-renderer";
import {useCsrfStore} from "jrmc/vue/shared/store/csrf";
import CsrfTokenApi from "jrmc/vue/shared/api/csrf-token";
import {NoCsrfHttpClient} from "jrmc/vue/shared/api/no-csrf-http-client";

export abstract class HttpClient extends NoCsrfHttpClient {

  public constructor(baseURL: string, ssrContext?: SSRContext) {
    super(baseURL, ssrContext);
  }

  protected _initializeResponseInterceptor () {
    this.instance.interceptors.request.use(
      async (config) => {
        if (config.method !== 'get') {
          let csrfToken = CsrfService.getCsrfToken()
          // If not initialized yet, load the CSRF token
          if (!csrfToken) {
            const store = useCsrfStore();
            csrfToken = (await CsrfTokenApi.getCsrfToken()).token;
            store.setCsrfToken(csrfToken);
          }

          if (csrfToken && config.headers) {
            config.headers['X-CSRF-TOKEN'] = csrfToken
          }
        }

        return config
      },

      (error) => {
        return Promise.reject(error)
      }
    )
    this.instance.interceptors.response.use(
      this._handleResponse,
      this._handleError,
    );
  };
}
