import {CsrfToken} from "jrmc/vue/shared/models/CsrfToken";
import {NoCsrfHttpClient} from "jrmc/vue/shared/api/no-csrf-http-client";

export default new class CsrfTokenApi extends NoCsrfHttpClient {
  public constructor() {
    super('/api/v1/csrf_token');
  }

  async getCsrfToken() {
    return this.instance.get<CsrfToken>('');
  }
}
